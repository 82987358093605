import ProjectOne from "../assets/images/project_one.jpg";
import ProjectTwo from "../assets/images/project_two.jpg";
import ProjectThree from "../assets/images/project_three.jpg";
import ProjectFour from "../assets/images/project_four.jpg";
import ProjectFive from "../assets/images/project_five.jpg";
import ProjectSix from "../assets/images/project_six.jpg";

export const projectsImageData = [
  {
    imageURL: ProjectOne,
    imageDescription: "Ship dockyard",
    projectTitle: "Custom Clearance",
    path: "/",
  },
  {
    imageURL: ProjectTwo,
    imageDescription: "Moving ship",
    projectTitle: "Sea Transport",
    path: "/",
  },
  {
    imageURL: ProjectThree,
    imageDescription: "Cargo ship",
    projectTitle: "Transhipment",
    path: "/",
  },
  {
    imageURL: ProjectFour,
    imageDescription: "Cargo ship",
    projectTitle: "Project Cargo",
    path: "/",
  },
  {
    imageURL: ProjectFive,
    imageDescription: "Cargo plane",
    projectTitle: "Air Transport",
    path: "/",
  },
  {
    imageURL: ProjectSix,
    imageDescription: "Truck",
    projectTitle: "Road Transport",
    path: "/",
  },
];
