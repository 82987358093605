import SlideImage1 from '../pages/home/assets/image/slide_1.jpg'
// import SlideImage5 from '../pages/home/assets/image/slide_5.jpg'
import SlideImage3 from '../pages/home/assets/image/slide_3.jpg'
import SlideImage4 from '../pages/home/assets/image/slide_4.jpg'
import SlideImage6 from '../pages/home/assets/image/slide_5.jpg'


export const imageSliderData = [
  { imageUrl: SlideImage1, description: "slider image" },
  // { imageUrl: SlideImage5, description: "slider image" },
  { imageUrl: SlideImage3, description: "slider image" },
  { imageUrl: SlideImage4, description: "slider image" },
 { imageUrl: SlideImage6, description: "slider image" }
]