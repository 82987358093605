export const ImpVegetables = [
    {
      "title": "Crunchy Carrots: Nature's Sweet Snack",
      "para": "Carrots are vibrant orange vegetables rich in beta-carotene, a precursor to vitamin A. Known for improving vision and skin health, carrots can be enjoyed raw, in salads, or cooked in various dishes.",
      "imageURL": "https://media.istockphoto.com/id/831053534/photo/ingredients-for-carrot-soup.jpg?s=612x612&w=0&k=20&c=fEQ61AdG_gq4h_5qXts86sbWdUPd6W9onNm7Sr77VMY="
    },
    {
      "title": "Fresh Spinach: A Nutritional Powerhouse",
      "para": "Spinach is a leafy green loaded with iron, vitamins, and antioxidants. It's a versatile vegetable that can be eaten raw in salads, sautéed, or added to soups and smoothies for a health boost.",
      "imageURL": "https://media.istockphoto.com/id/522189977/photo/spinach.jpg?s=612x612&w=0&k=20&c=WnfMaNx-yOhh393DZ4lyG6tF3Hp2osD6PEmuOKsTvWs="
    },
    {
      "title": "Broccoli: A Superfood for Wellness",
      "para": "Broccoli is a cruciferous vegetable rich in vitamins C and K, fiber, and antioxidants. It supports immune health and detoxification, making it a great addition to stir-fries, soups, or steamed dishes.",
      "imageURL": "https://media.istockphoto.com/id/518951178/photo/fresh-raw-green-broccoli-in-bowl.jpg?s=612x612&w=0&k=20&c=wP_y_qqs6_hFVsnkE4MCHLNOOdQ11sg5MBuOjg2AygA="
    },
    {
      "title": "Tomatoes: Juicy and Flavorful",
      "para": "Tomatoes are juicy vegetables (or fruits, botanically!) that are rich in lycopene, a powerful antioxidant. They are used in sauces, salads, and soups, adding a burst of tangy flavor to any dish.",
      "imageURL": "https://media.istockphoto.com/id/589985234/photo/homegrown-tomatoes.jpg?s=612x612&w=0&k=20&c=uzUhfFsfesTDClRQ_3PACAAS5SIb8UR1RyU9FqAVAco="
    },
    {
      "title": "Potatoes: Comfort Food in Every Bite",
      "para": "Potatoes are a versatile staple vegetable that can be baked, mashed, fried, or boiled. They are rich in potassium and carbohydrates, providing energy and comfort in countless dishes worldwide.",
      "imageURL": "https://media.istockphoto.com/id/698717842/photo/fresh-potatoes-in-sack-on-wooden-table.jpg?s=612x612&w=0&k=20&c=F_X00OtAWlBp5uRgtj9aTiwWmJmasexPMEw2lplh8MA="
    },
    {
      "title": "Cauliflower: Low-Carb Versatility",
      "para": "Cauliflower is a cruciferous vegetable loved for its low-carb, high-fiber profile. It can be roasted, steamed, or even mashed as a healthy substitute for traditional carb-heavy ingredients.",
      "imageURL": "https://media.istockphoto.com/id/1097064372/photo/cauliflower-head-with-leaves-in-hands-of-woman-farmer.jpg?s=612x612&w=0&k=20&c=YVMYbHwXPcbtJkdUwrsK0kSoRHTJa4fq0keBqCxv_xA="
    },
    {
      "title": "Eggplants: A Mediterranean Delight",
      "para": "Eggplants, or aubergines, have a rich, creamy texture when cooked. They are high in fiber and antioxidants and are perfect for grilling, roasting, or as a base for hearty vegetarian dishes.",
      "imageURL": "https://media.istockphoto.com/id/1328668312/photo/whole-eggplant-on-wooden-background.jpg?s=612x612&w=0&k=20&c=cg8g7NuNfCdU-WdBEJosfVcOkPc-PLKpo0ZKYNKDyMY="
    },
    {
      "title": "Onions: The Culinary Staple",
      "para": "Onions add a depth of flavor to almost any dish. They are rich in antioxidants and have anti-inflammatory properties, making them a must-have in kitchens worldwide, whether raw or cooked.",
      "imageURL": "https://media.istockphoto.com/id/1181631588/photo/onions-for-sale-in-the-weekly-market-malkapur-maharashtra.jpg?s=612x612&w=0&k=20&c=KYz1slV6Ly-T7v2vH7jns7ab9i_M6Atjq52uNPh3gRo="
    },
  ]
  