import * as BsIcons from 'react-icons/bs'

export const socialLinks = [
  // {
  //   name: "Facebook",
  //   linkPath: '#',
  //   icon: <BsIcons.BsFacebook />
  // },
  // {
  //   name: "Twitter",
  //   linkPath: '#',
  //   icon: <BsIcons.BsTwitter />
  // },
  {
    name: "Instagram",
    linkPath: 'https://www.instagram.com/omlc_cargo/',
    icon: <BsIcons.BsInstagram />
  },
  {
    name: "Linkedin",
    linkPath: 'https://www.linkedin.com/company/omlc-cargo/',
    icon: <BsIcons.BsLinkedin />
  }
]