import React from "react";
import "./sass/Exports.scss"
// import { Link } from "react-router-dom";
// import * as FaIcons from "react-icons/fa";
import { Impfruits } from "../../data/Impfruits";
import { Impspices } from "../../data/Impspices";
import {ImpVegetables} from "../../data/ImpVegetables";
import galleryData from "../../data/galleryData";
const Banner = () => {
  return (
    
    <>
      <section className="h-[30vh] md:h-[40vh] w-full export_banner">
    
      </section>
      <section id="articles"  className="hightlights py-[5rem] bg-white overflow-hidden">
        <div className="px-[20px] lg:px-[100px] w-full">
       
          <div className="mt-5">
            <h1
              // data-aos="fade-right"
              // data-aos-duration="2000"
              className="text-gray-800 mt-[50px] font-[700] text-[40px] leading-[40px] md:text-[66px] md:leading-[66px] xl:text-[84px] font-outfit xl:leading-[114px]">
         What we do offer ?
            </h1>
            {/* <hr className="mt-9" /> */}
          </div>

          <div className="">
            <h3
            //   data-aos="fade-up"
            //   data-aos-duration="2000"
              className="mt-5 text-[30px] font-[500] font-jost leading-[18px] lg:text-[32px] lg:leading-[32px] text-gray-800">
        Spices
            </h3>

            <div className="mt-9">
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[2rem]">
                {Impspices.map((data, index) => {
                  const { title, date, category, imageURL,para } = data;
                  return (
                    <div
                      key={index}
                    //   data-aos="fade-up"
                    //   data-aos-duration="2000"
                      className="no-underline">
                      <div className="highlight_wrap">
                        <div className="news_image_wrapper h-[195px] overflow-hidden">
                          <img src={imageURL} width={"100%"} alt={title} />
                        </div>
                        <div className="mt-2">
                          <div className="text-gray-500 font-jost font-[500]">
                          </div>
                          <h3 className="text-gray-800 font-outfit font-[700] truncate text-[25px] leading-[25px]">
                            {title}
                          </h3>
                          <p className="text-gray-800 font-outfit font-[300] text-[15px]">
                            {para}
                          </p>
                          <a class="custom-button mt-2" href="https://wa.me/+918928447937?text=Hello%21%20I%20am%20interested%20in%20your%20services%20and%20would%20like%20to%20get%20a%20quote.%20Please%20let%20me%20know%20the%20details." target="_blank">Get a Quote</a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-8">
            <h3
              // data-aos="fade-up"
              // data-aos-duration="2000"
              className="mt-12 text-[30px] font-[500] font-jost leading-[18px] lg:text-[32px] lg:leading-[32px] text-gray-800 font-[500]">
      Vegetables
            </h3>

            <div className="mt-9">
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[2rem]">
                {ImpVegetables.map((data, index) => {
                  const { title, date, category, imageURL,para } = data;
                  return (
                    <div
                      key={index}
                      // data-aos="fade-up"
                      // data-aos-duration="2000"
                      className="no-underline">
                      <div className="highlight_wrap">
                        <div className="news_image_wrapper h-[195px] overflow-hidden">
                          <img src={imageURL} width={"100%"} alt={title} />
                        </div>
                        <div className="mt-2">
                          <div className="text-gray-500 font-jost font-[500]">
                          </div>
                          <h3 className="text-gray-800 font-outfit font-[700] truncate text-[25px] leading-[25px]">
                            {title}
                          </h3>
                          <p className="text-gray-800 font-outfit font-[300] text-[15px]">
                            {para}
                          </p>
                          <a class="custom-button mt-2" href="https://wa.me/+918928447937?text=Hello%21%20I%20am%20interested%20in%20your%20services%20and%20would%20like%20to%20get%20a%20quote.%20Please%20let%20me%20know%20the%20details">Get a Quote</a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-8">
            <h3
              // data-aos="fade-up"
              // data-aos-duration="2000"
              className="mt-5 text-[30px] font-[500] font-jost leading-[18px] lg:text-[32px] lg:leading-[32px] text-gray-800 font-[500]">
     Fruits
            </h3>

            <div className="mt-9">
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[2rem]">
                {Impfruits.map((data, index) => {
                  const { title, date, category, imageURL,para } = data;
                  return (
                    <div
                      key={index}
                      // data-aos="fade-up"
                      // data-aos-duration="2000"
                      className="no-underline">
                      <div className="highlight_wrap">
                        <div className="news_image_wrapper h-[195px] overflow-hidden">
                          <img src={imageURL} width={"100%"} alt={title} />
                        </div>
                        <div className="mt-2">
                          <div className="text-gray-500 font-jost font-[500]">
                          </div>
                          <h3 className="text-gray-800 font-outfit font-[700] truncate text-[25px] leading-[25px]">
                            {title}
                          </h3>
                          <p className="text-gray-800 font-outfit font-[300] text-[15px]">
                            {para}
                          </p>

                        </div>
                        <a class="custom-button mt-2" href="https://wa.me/+918928447937?text=Hello%21%20I%20am%20interested%20in%20your%20services%20and%20would%20like%20to%20get%20a%20quote.%20Please%20let%20me%20know%20the%20details." target="_blank">Get a Quote</a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <h1
            //   data-aos="fade-right"
            //   data-aos-duration="2000"
              className="text-gray-800 mt-[50px] font-[700] text-[40px] leading-[40px] md:text-[46px] md:leading-[66px] xl:text-[64px] font-outfit xl:leading-[114px]">
       Gallery
            </h1>
            <div className="gallery-container">
     
     {galleryData.map((item, index) => (
       <div key={index} className="card">
         {/* Conditionally Render Image or Video */}
         {item.type === "image" ? (
           <img src={item.mediaURL} alt={item.title} className="media" />
         ) : (
           <video controls className="media">
             <source src={item.mediaURL} type="video/mp4" />
             Your browser does not support the video tag.
           </video>
         )}
         <h3 className="title">{item.title}</h3>
         <p className="description">{item.description}</p>
       </div>
     ))}
   </div>
   <h1
  className="text-gray-800 mt-[30px] mb-[20px] font-[700] text-[40px] leading-[40px] md:text-[46px] md:leading-[66px] xl:text-[64px] font-outfit xl:leading-[114px]">
  Certificates
</h1>

<div className="flex justify-center items-center p-4">
  <div className="w-full max-w-xs md:max-w-sm lg:max-w-md border rounded-md shadow-lg" style={{ boxShadow: '0px 12px 18px #232D60' }}>
    <img
      src="https://ik.imagekit.io/99djpd8k3/OMLC%20Cargo/OMLC%20License-1.jpg?updatedAt=1733574825144"
      alt="LLP Certificate of Incorporation"
      className="w-full h-auto rounded-md"
    />
  </div>
</div>

        </div>
      
       
      </section>
    </>
  );
};

export default Banner;
