export const Impspices = [
    {
      "title": "Cloves: Aromatic Spice for Diverse Cuisines",
      "para": "Cloves are aromatic dried flower buds widely used in Asian, African, and Middle Eastern cuisines. Known for their unique flavor and medicinal properties.",
      "imageURL": "https://media.istockphoto.com/id/471346177/photo/cloves-closeup.jpg?s=612x612&w=0&k=20&c=A9ndvh6JRULUPL7ZK0xb0uVyMjPyFUTnKyzg4fkbwfY="
    },
    {
      "title": "Hulled Sesame Seeds: Nutritional and Versatile",
      "para": "Hulled sesame seeds are produced by removing the outer husk, making them a rich source of nutrients.Seeds are popular in baking, cooking, and garnishing, offering a subtle nutty flavor.",
      "imageURL": "https://media.istockphoto.com/id/175492267/photo/integral-sesame-seads.jpg?s=612x612&w=0&k=20&c=k0m4X147hLbv4lVmhWU26p9rmqahP3hO-HYoiuOOQFY="
    },
    {
      "title": "Black Pepper: The King of Spices",
      "para": "Black pepper, harvested from the pepper plant, is a staple in global cuisines. Renowned for its pungent flavor and health benefits, it is used as a seasoning and in spice blends.",
      "imageURL": "https://media.istockphoto.com/id/979264404/photo/ground-black-pepper.jpg?s=612x612&w=0&k=20&c=nNx14USWHFtoBHNp5kTzzDoUZ8hvITf1f4RQ-unmDcE="
    },
    {
      "title": "Nutmeg: A Spice with Warm Flavors",
      "para": "Nutmeg is derived from the seeds of the nutmeg tree. It is cherished for its warm, nutty flavor and is used in sweet and savory dishes, beverages, and baked goods.",
      "imageURL": "https://media.istockphoto.com/id/508444295/photo/nutmeg.jpg?s=612x612&w=0&k=20&c=6Zw-naEVpOXQx8XeFXNJq17QSpJUDfbfOfHbh5JO7aA="
    },
    {
      "title": "Dried Ginger: Flavorful and Medicinal",
      "para": "Dried ginger is a versatile spice used as a condiment and seasoning. Known for its aromatic flavor, it is also valued for its health-boosting properties in traditional medicine.",
      "imageURL": "https://media.istockphoto.com/id/1243591175/photo/dry-ginger-roots.jpg?s=612x612&w=0&k=20&c=BjnZWlPA2Drc0HS2uXFBbCrA2RyGa-zk8Z-5DUWbY_g="
    },
    {
      "title": "Coriander Seeds: A Culinary Essential",
      "para": "Coriander seeds are a cornerstone of global cuisines, prized for their citrusy, nutty flavor. They are used whole or ground to enhance the taste of curries, soups, and marinades.",
      "imageURL": "https://media.istockphoto.com/id/636351986/photo/coriander-organic-seeds-in-small-white-bowl.jpg?s=612x612&w=0&k=20&c=YC8UXQWVw7PYaKmvvP8D9yHZrYzOk3P7SjYvFHUXbRs="
    },
    {
      "title": "Bay Leaves: Fragrant Spice for Rich Dishes",
      "para": "Bay leaves are aromatic and enhance the flavor of soups, stews, and sauces. Used whole, they are removed before serving, leaving a subtle and rich aroma.",
      "imageURL": "https://media.istockphoto.com/id/911769366/photo/dry-bay-leaves-laurel-aromatic-indian-spices-wooden-spoon.jpg?s=612x612&w=0&k=20&c=KpOCfrZV9fNz-m3vIiPN9GS941CF6mwXkt_bN9G1zXg="
    },
    {
      "title": "Fenugreek Seeds: A Healthful Addition",
      "para": "Fenugreek seeds, known for their slightly bitter and nutty taste, are used in spices blends and medicinal preparations. They add depth to curries and pickles.",
      "imageURL": "https://media.istockphoto.com/id/1145687681/photo/fenugreek-seeds.jpg?s=612x612&w=0&k=20&c=0ia3yoMm52Ek4-zu0u2tg-gtELM_BFjbhd8cYBXt3LQ="
    },
    {
      "title": "Senna Leaves: Herbal Wellness",
      "para": "Senna leaves are known for their herbal properties and are widely used in traditional medicine. They are processed into powders, teas, and capsules to support health.",
      "imageURL": "https://media.istockphoto.com/id/1491161399/photo/medication-senna-dry-tea-leaf.jpg?s=612x612&w=0&k=20&c=A3EDP80NAMOF4W8vX0GWCgONuhH7rFx4Evtrdyd_UPA="
    },
    {
      "title": "Ajwain: Bold and Aromatic",
      "para": "Ajwain seeds, also known as Bishop's weed, are aromatic and slightly bitter. A small amount enhances the flavor of savory dishes and aids digestion.",
      "imageURL": "https://media.istockphoto.com/id/1500897109/photo/top-view-of-ammi-seeds-indian-spice-ajwain.jpg?s=612x612&w=0&k=20&c=B1eiLDkP15O8KOOAP8QcllwXNUYMYvoeBZwG2LzVHcs="
    },
    {
      "title": "Green Cardamom: Precious and Flavorful",
      "para": "Green cardamom is a highly sought-after spice used in sweet and savory recipes. Its distinct aroma and flavor make it a prized ingredient in beverages and desserts.",
      "imageURL": "https://media.istockphoto.com/id/518709136/photo/green-cardamom-pods-in-steel-bowl.jpg?s=612x612&w=0&k=20&c=uMfiFWLROWcsyeqG6zcUQb83nxo6VI5_o7aCQUiEmpw="
    },
    {
      "title": "Turmeric Fingers: Golden Spice of Life",
      "para": "Turmeric fingers are known for their vibrant color and earthy flavor. They are a cornerstone in spice mixes and are valued for their anti-inflammatory and antioxidant properties.",
      "imageURL": "https://media.istockphoto.com/id/505751448/photo/turmeric.jpg?s=612x612&w=0&k=20&c=ne_NLSJPPz3ZaxT1vjzzytYbceHl8tgn_OCI6hXHnP8="
    }
  ]
  