export const tabButtonsData = [
  { title: "History", category: "history" },
  { title: "Mission", category: "mission" },
  { title: "Vision", category: "vision" }
];

export const tabContents = [
  { detail: "At OMLC Cargo, we are more than just a logistics company – we are the architects of seamless supply chains, the navigators of global trade, and the enablers of business growth. With a passion for innovation and a commitment to excellence, we have been at the forefront of the logistics industry for nearly a decade.Founded in 2015, OMLC Cargo began as a vision to redefine logistics. Our journey started with a small team and a big idea: to provide comprehensive, customer-centric solutions that transcend borders and challenges. Through dedication and hard work, we have grown into a global logistics leader, connecting businesses and markets across continents.", category: "history" },
  { detail: "At OMLC Cargo, our mission is to be the catalyst for global commerce. We are committed to delivering innovative, reliable, and customized logistics solutions that enable businesses to flourish in an ever-changing world. With an unwavering dedication to excellence and a deep passion for customer success, we aim to create seamless supply chains, bridge distances, and unlock opportunities. Leveraging our expertise, technology-driven approach, and steadfast commitment, we strive to be the preferred partner for businesses navigating the complexities of logistics with confidence.", category: "mission" },
  { detail: "At OMLC Cargo, our vision is to redefine the logistics landscape by establishing new benchmarks of excellence and innovation. We foresee a future where businesses connect effortlessly, borders become invisible, and opportunities are boundless. Through the integration of cutting-edge technology, sustainable practices, and a relentless drive for improvement, we aim to be the transformative force in global trade. By consistently exceeding expectations, embracing change, and delivering unparalleled value to our clients, we aspire to be the trusted global leader in shaping the future of logistics.", category: "vision" }
];