import staffTwo from "../assets/images/t-02.jpg";
import staffThree from "../assets/images/t-03.jpg";

export const staffData = [
  {
    staffName: "Anand Mahadeshwar",
    staffPosition: "Proprietor",
    imageUrl: staffTwo,
    staffLinkedin:"https://www.linkedin.com/in/anand-mahadeshwar-469309307/"
  },
  {
    staffName: "Omkar Mahadeshwar",
    staffPosition: "Managing Director",
    imageUrl: staffThree,
    staffLinkedin:"https://www.linkedin.com/in/omkar-mahadeshwar-5b014b202/"
  },
  // {
  //   staffName: "Allen Iverson",
  //   staffPosition: "Project Manager",
  //   imageUrl: staffTwo,
  // },
];
