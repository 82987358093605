import React from "react";
import SalesTwoImage from "../assets/images/sales_two.jpg";

const SalesTwo = () => {
  return (
    <>
      <section className="py-10">
        <div className="w-full lg:px-[100px] px-[20px]">
          <div className="lg:flex gap-4 items-center">
            <div className="basis-1/2">
              <div
                data-aos="fade-right"
                data-aos-duration="2000"
                className="text-gray-500 text-lg font-jost">
               At OMLC Cargo, we understand that every shipment, every route, and every client is unique. That’s why we approach each project with a personalized touch, ensuring tailored solutions that meet your specific requirements. Our team of seasoned professionals collaborates closely with you to analyze your needs in-depth and design a logistics strategy that maximizes efficiency and minimizes costs. We don’t believe in one-size-fits-all solutions; instead, we take the time to understand your business, shipment requirements, and logistical challenges. This thorough understanding allows us to create customized plans that address your specific needs, ensuring smooth and efficient operations. From warehousing and distribution to international freight forwarding, we handle every detail meticulously, so you can focus on what you do best – growing your business. Our experienced professionals bring a wealth of knowledge and expertise, offering insights and recommendations that optimize your logistics processes and enhance your overall business performance.
              </div>
            </div>
            <div className="basis-1/2">
              <img
                data-aos="fade-left"
                data-aos-duration="2000"
                src={SalesTwoImage}
                width={"100%"}
                alt="sales"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SalesTwo;
