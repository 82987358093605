import React from "react";

const Banner = () => {
  return (
    <>
      <section className="h-[30vh] md:h-[40vh] w-full about_banner"></section>
    </>
  );
};

export default Banner;
