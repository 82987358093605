export const Impfruits = [
    {
      "title": "Juicy Mangoes: The King of Fruits",
      "para": "Mangoes are a tropical delight loved worldwide for their sweet, rich flavor and juicy texture. Packed with vitamins A and C, mangoes are as nutritious as they are delicious, making them a staple in desserts, smoothies, and salads.",
      "imageURL": "https://media.istockphoto.com/id/1152750103/photo/sliced-alphonso-mangoes.jpg?s=612x612&w=0&k=20&c=hEnXEjJC0WPVA7OzTIVY_DMeexjSAX_Dk5RCEgOX_S8="
    },
    {
      "title": "Fresh Apples: A Daily Health Boost",
      "para": "Apples are crisp, sweet, and versatile fruits that come in a variety of colors and flavors. They are rich in fiber and antioxidants, promoting heart health and aiding digestion. Enjoy them fresh, in pies, or as juice.",
      "imageURL": "https://media.istockphoto.com/id/614871876/photo/apple-isolated-on-wood-background.jpg?s=612x612&w=0&k=20&c=_8ShFA2p-xbTBMz0g7JUSsfd329GBkyj47dzW3F1mqc="
    },
    {
      "title": "Bananas: Nature's Energy Snack",
      "para": "Bananas are a convenient, energy-packed fruit perfect for a quick snack. Rich in potassium and natural sugars, they are excellent for boosting energy levels and maintaining healthy digestion.",
      "imageURL": "https://media.istockphoto.com/id/1494763483/photo/banana-concept.jpg?s=612x612&w=0&k=20&c=ZeVP-L6ClmyT-i0N-QAbDK7q37uHhrzg7KOzMkaOtg4="
    },
    {
      "title": "Citrus Oranges: Vitamin C Powerhouse",
      "para": "Oranges are zesty and refreshing fruits that are a fantastic source of vitamin C. Known for their immune-boosting properties, oranges are a favorite in juices and as a healthy snack.",
      "imageURL": "https://media.istockphoto.com/id/1314957407/photo/lots-of-fresh-ripe-peeled-oranges-good-source-of-vitamin-c-immunity-booster-fruit.jpg?s=612x612&w=0&k=20&c=XbIpf7BEgkRpamIOZwJ4bVAB0fT4ZWM99H6G84qlel4="
    },
    {
      "title": "Sweet Grapes: Nature's Candy",
      "para": "Grapes are small, sweet, and juicy fruits that are enjoyed fresh or in raisins and wine. They are loaded with antioxidants, promoting heart health and providing a burst of natural energy.",
      "imageURL": "https://media.istockphoto.com/id/842928214/photo/fresh-grapes-in-the-basket.jpg?s=612x612&w=0&k=20&c=SPUyIifWeeXCda_P8sqE0WFvZ-pDyaQ4aZLjNHpYEc4="
    },
    {
      "title": "Luscious Strawberries: The Berry of Love",
      "para": "Strawberries are vibrant red fruits known for their sweet, tangy flavor. Rich in vitamin C and antioxidants, they are great in desserts, smoothies, or eaten fresh.",
      "imageURL": "https://media.istockphoto.com/id/883703976/photo/strawberry-background-strawberries.jpg?s=612x612&w=0&k=20&c=CBHaw9-SNxpSBxToW1oFnGkiW6Eg9hYWcAZa0HZI4sI="
    },
    {
      "title": "Pineapples: A Tropical Treat",
      "para": "Pineapples are tropical fruits with a sweet and tangy flavor. They are rich in bromelain, an enzyme that aids digestion, and are a favorite in salads, juices, and desserts.",
      "imageURL": "https://media.istockphoto.com/id/1190071841/photo/pineapple-seller.jpg?s=612x612&w=0&k=20&c=ea_Ts6IwY1ov_LRWx_Ki9tEurv3aLobPvdydTmj3tZA="
    },
    {
      "title": "Pomegranates: Jewels of Health",
      "para": "Pomegranates are packed with antioxidants and vitamins, making them a superfood for heart health and glowing skin. Their juicy, ruby-red seeds add a burst of flavor to any dish.",
      "imageURL": "https://media.istockphoto.com/id/1210998321/photo/opened-pomegranate-display-in-a-market.jpg?s=612x612&w=0&k=20&c=SFN8zg52zkrwGD_Qed53DE7xvfOqwJpYos9t9LbOPcU="
    },
    {
      "title": "Watermelons: The Ultimate Summer Refreshment",
      "para": "Watermelons are juicy and hydrating fruits, perfect for hot summer days. They are a great source of vitamins A and C and are known for their high water content and refreshing taste.",
      "imageURL": "https://media.istockphoto.com/id/578273202/photo/fresh-sliced-watermelon-wooden-background.jpg?s=612x612&w=0&k=20&c=jyRCeZdU2GOP1uE0lQfIBaZusqzAZO3e6sa0j5cNww4="
    },
    {
      "title": "Papayas: A Tropical Health Booster",
      "para": "Papayas are tropical fruits known for their soft texture and sweet flavor. Rich in vitamins, enzymes, and antioxidants, they are excellent for digestive health and glowing skin.",
      "imageURL": "https://media.istockphoto.com/id/1163930184/photo/papaya-on-wooden-background.jpg?s=612x612&w=0&k=20&c=W-1l2k1J8raJGvUb1NM0oeqEdC2DqRbt-2gpzfXL01o="
    }
  ]
  