export const aboutSalesText = [
  {
    id: 1,
    title: "Unmatched Reliability",
    details:
"Our dedication to ensuring your goods reach their destination safely and on time makes us a trusted partner in logistics. We pride ourselves on a track record of reliability and consistency, offering peace of mind for your business operations."  },
  {
    id: 2,
    title: "Global Network",
    details:"With a robust network of international partners and strategic locations worldwide, OMLC Cargo provides seamless logistics services that connect your business to global markets efficiently and effectively."  },
  {
    id: 3,
    title: "Cutting-Edge Technology",
    details:"Utilizing state-of-the-art technology and continuously upgrading our IT resources, we offer real-time tracking, transparent status reports, and optimized logistics solutions that keep you informed and in control."  },
  {
    id: 4,
    title: "Comprehensive Logistics Solutions",
    details:
      "From air and ocean freight forwarding to customs brokerage, ground and rail distribution, and project cargo management, our comprehensive range of services ensures all your logistics needs are met under one roof.",
  },
  {
    id: 5,
    title: "Customer-Centric Approach",
    details:"We are committed to delivering exceptional customer service, offering tailored solutions, affordable pricing, and modern fleet options. Our mission is to exceed your expectations and provide a seamless logistics experience that supports your business growth."  },
];
