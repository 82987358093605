export const featuresData = [
  {
    fistFeature: "Warehousing",
    secondFeature: "E-commerce Logistics"
  },
  {
    fistFeature: "Freight Forwarding",
    secondFeature: "Last-Mile Delivery"
  },
  {
    fistFeature: "Supply Management",
    secondFeature: "Cold Chain Logistics"
  }
]