const galleryData = [
    {
        mediaURL: "https://ik.imagekit.io/99djpd8k3/OMLC%20Cargo/OMLC%20Cargo%20Export.mp4?updatedAt=1733426422167",
        type: "video"},
    {
      mediaURL: "https://ik.imagekit.io/99djpd8k3/OMLC%20Cargo/WhatsApp%20Video%202024-12-04%20at%2008.37.51.mp4?updatedAt=1733425748753",
      type: "video"
    },
    {
        mediaURL: "https://ik.imagekit.io/99djpd8k3/OMLC%20Cargo/WhatsApp%20Video%202024-12-04%20at%2008.37.26.mp4?updatedAt=1733425747650",
        type: "video"
      },
      {
        mediaURL: "https://ik.imagekit.io/99djpd8k3/OMLC%20Cargo/WhatsApp%20Image%202024-12-04%20at%2008.41.37.jpeg?updatedAt=1733425747375",
        type: "image"
      },
      {
        mediaURL: "https://ik.imagekit.io/99djpd8k3/OMLC%20Cargo/WhatsApp%20Image%202024-12-04%20at%2008.41.57.jpeg?updatedAt=1733425747095",
        type: "image"
      },
    // Add more vegetables as needed...
  ];
  
  export default galleryData;
  